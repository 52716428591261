import * as React from "react"
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


// markup
const Impressum = () => {
  return (
    <>
      <Navbar/>
      <div className="container-sm pt-9">
        <div className="content text-center">
          <h1>404</h1>
        <h2>VERLAUFEN? NIMM EINFACH DEN WEG ZURÜCK!</h2>
        </div>{/* /.content */}
      </div>{/* /.container-sm */}
      <Footer/>
    </>
  )
}

export default Impressum
